import React from 'react'

const ReverseProxyCase = () =>
 <div>
    <h1>Reverse Proxy Feature</h1>
    <p>
        Edge Server reverse proxy Feature: should show youtube video 
    </p>
    <br />
    <iframe width="560" height="315" src="https://www.doxa.cloud/reverse/XHvmX3FhTwU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
  </div>

export default ReverseProxyCase
