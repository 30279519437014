import React from 'react'
import { Query } from "@apollo/client/react/components"
import { showGeoLocation } from '../queries'

const showGeolocation = ({ error, loading, data: { geoLocation } = {} }) => {
  if (loading) return "Loading..."
  if (error) return `Error! ${error.message}`

  return <p><h1>Custom Header Test </h1><br /> Country Code : {geoLocation.country_code}<br />Customer IP : {geoLocation.true_ip}</p>
}

const GeoLocationPage = () =>
  <Query query={showGeoLocation}>
    {showGeolocation}
  </Query>

export default GeoLocationPage
