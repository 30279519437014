import React from 'react'

const ImageProcessingCase = () =>
 <div>
    <h1>Image Processing Feature</h1>
    <p>
        Edge Server image processing Feature: The resize image should resize to 30px 
    </p>
    <br />
    <table>
        <tr>
            <td>Original Image</td>
            <td>Resize Image</td>
        </tr>
        <tr>
            <td><img src="/images/image1.png" alt="original"/></td>
            <td><img src="/images/image1.png?impolicy=resize&imwidth=30" alt="resize"/></td>
        </tr>
    </table>
  </div>

export default ImageProcessingCase
