import React from 'react'
import { Query } from "@apollo/client/react/components"
import { getGreeting } from '../queries'

const showGreeting = ({ error, loading, data: { greeting } = {} }) => {
  console.log(greeting, loading)
  if (loading) return "Loading..."
  if (error) return `Error! ${error.message}`

  return <p><h1>API Cache Test</h1><p>Using GraphQL Persisted Concept and edge server should cache the content</p><p>Content: {greeting.name}, {greeting.text}</p></p>
}

const ApiCacheCase = () =>
  <Query query={getGreeting} variables={{ name: 'Cadence' }}>
    {showGreeting}
  </Query>

export default ApiCacheCase
