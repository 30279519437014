import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Link, BrowserRouter, Route, Routes } from 'react-router-dom'
import { ApolloProvider } from '@apollo/react-hooks' 
import { HttpLink, InMemoryCache, ApolloClient } from '@apollo/client'
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { sha256 } from 'crypto-hash';
import Home from './components/home'
import ApiCacheCase from './components/apiCacheCase'
import PageTwo from './components/pageTwo'
import GeoLocationPage from './components/geolocationCase'
import ImageProcessingCase from './components/imageCase'
import ReverseProxyCase from './components/reverseCase'

const httpLink = new HttpLink({ uri: "https://www.doxa.cloud/graphql" });
const persistedQueriesLink = createPersistedQueryLink({ sha256, useGETForHashedQueries: true });

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: persistedQueriesLink.concat(httpLink),
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <div>
        <ul>
          <li><Link to='/'>Home</Link></li>
          <li><Link to='/graphql-test'>GraphQL API Cache Test</Link></li>
          <li><Link to='/pagetwo'>Page Two</Link></li>
          <li><Link to='/image-test'>Image Test</Link></li>
          <li><Link to='/reverse-test'>Reverse Proxy Test</Link></li>
          <li><Link to='/geolocation-test'>GeoLocation Test</Link></li>
        </ul>

        <hr />
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/graphql-test' element={<ApiCacheCase />} />
          <Route path='/pagetwo' element={<PageTwo />} />
          <Route path='/image-test' element={<ImageProcessingCase />} />
          <Route path='/reverse-test' element={<ReverseProxyCase />} />
          <Route path='/geolocation-test' element={<GeoLocationPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  </ApolloProvider>
)
