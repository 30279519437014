import { gql } from '@apollo/client';

const getGreeting = gql`
  query Greeting($name: String) {
    greeting(name: $name) {
      name
      text
    }
  }
`

const extendGreeting = gql`
  query Greeting($name: String) {
    greeting(name: $name) {
      name
      age
      profession
      text
    }
  }
`

const showGeoLocation = gql`
  query GeoLocation {
    geoLocation {    
        country_code
        true_ip
        __typename
    }
  }
`

export {
  getGreeting,
  extendGreeting,
  showGeoLocation
}
